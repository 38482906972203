@charset "UTF-8";
html {
  margin: 0;
  overscroll-behavior: none;
}
body {
  font-size: 16px;
  margin: 0 auto;
  padding: 0;
  max-width: 900px;
}
h1,
h3 {
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
}
h2 {
  float: left;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
}
p,
pre {
  margin: 8px 0;
  padding: 0 10%;
  width: 80%;
}
a {
  margin: 0;
  padding: 0;
  transition: 1s;
  width: auto;
}
nav {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}
nav a {
  padding: 0 2px;
}
section {
  float: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
hr {
  display: none;
}
form {
  margin: 10px 0;
  padding: 0;
  text-align: left;
  width: 100%;
}
button {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
  padding: 0 10px;
  width: 100%;
}
input {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 2%;
  padding: 0;
  text-align: center;
  width: 96%;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
input,
select {
  font-size: 100%;
}
footer {
  margin: 0;
  padding: 10px 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
footer p {
  margin: 0;
  padding: 0;
  width: 100%;
}
img {
  margin: 0;
  max-width: 100%;
  padding: 0;
  vertical-align: middle;
}
p span span {
  font-style: normal;
}
span {
  font-style: normal;
}
u {
  width: 100%;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
}
.title {
  display: flex;
  align-items: center;
  float: left;
  width: 100%;
}
.animated-paragraph {
  margin-top: 100px;
}
.asterisk {
  display: none;
}
.small-caps {
  font-variant: small-caps;
}
.red-letter {
  font-style: normal;
}
@media only screen and (min-width: 1000px) {
  nav {
    text-align: left;
  }
  h2 {
    width: 50%;
  }
  hr {
    display: inline-block;
    margin: 10px 0;
    padding: 0;
    border: none;
    border-top: 5px solid black;
    width: 25%;
  }
  li {
    display: block;
    margin: 0;
    padding: 10px 0;
  }
  nav a {
    padding: 0 4px 0 0;
  }
  p {
    padding: 0;
    width: 100%;
  }
}
