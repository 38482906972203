@charset "UTF-8";
/* COLORS */
:root {
  --background_color: #f7f7f2;
  --alt_background: #e4e6c3;
  --link_color: #899878;
  --selected_link: #222725;
  --invert_text: #222725;
  --text_color: #121113;
  --jesus: #d13d3d;
}
body {
  background-color: var(--background_color);
}
h1,
h3 {
  color: var(--text_color);
}
h2 {
  color: var(--link_color);
}
p,
pre {
  color: var(--text_color);
}
p::selection {
  background: var(--alt_background);
}
a {
  color: var(--link_color);
}
a:hover {
  color: var(--selected_link);
}
u {
  color: var(--alt_background);
}
button {
  background-color: var(--link_color);
  color: var(--invert_text);
}
form {
  color: var(--invert_text);
}
input {
  background-color: var(--background_color);
  color: var(--invert_text);
}
input:focus::placeholder {
  color: transparent;
}
.header {
  background-color: var(--background_color);
}
::-webkit-scrollbar-track {
  background-color: var(--background_color);
}
::-webkit-scrollbar-thumb {
  background: var(--alt_background);
}
.red-letter {
  color: var(--jesus);
}
/* FONTS */
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap");
h1,
h2,
h3 {
  font-family: "Bree Serif", serif;
  font-variant: normal;
  font-weight: 400;
  line-height: 30px;
}
h4 {
  font-family: "Bree Serif", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-decoration: none;
}
h1 {
  font-size: 30px;
}
h2 {
  font-size: 15px;
}
h3 {
  font-size: 14px;
}
p,
a {
  font-family: "Bree Serif", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-decoration: none;
}
a {
  text-decoration: underline;
}
u {
  font-family: "Bree Serif", serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  text-decoration: none;
}
button {
  font-family: "Bree Serif", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-decoration: none;
}
form {
  font-family: "Bree Serif", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-decoration: none;
}
input {
  font-family: "Bree Serif", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-decoration: none;
}
.introtext {
  font-size: 28px;
}
.copy {
  font-size: 12px;
}
@media only screen and (min-width: 700px) {
  h1 {
    font-size: 40px;
    line-height: 40px;
  }
  h2 {
    font-size: 20px;
    line-height: 40px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
  p,
  a {
    font-size: 17px;
  }
  button {
    font-size: 16px;
  }
  input {
    font-size: 16px;
  }
  u {
    font-size: 18px;
  }
  .copy {
    font-size: 15px;
  }
}
@media only screen and (min-width: 1000px) {
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1100px) {
  h1 {
    font-size: 60px;
  }
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 22px;
  }
}
